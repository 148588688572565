"use client";

import React, { FC, useState } from "react";
import BannerCatalog from "../pages/Profile/components/banner-catalog";
import { IAccount } from "@/composables/account.type";
import { PublicAnnouncementType } from "@/common/types/public-announcement.type";
import { IPaymentLink } from "@/composables/paymentlink.type";
import { IUser } from "@/composables/user.type";
import Image from "next/image";

const DEFAULT_LOGO_URL = process.env.NEXT_PUBLIC_DEFAULT_USER_AVATAR;

type TopProfileType = {
  username: string;
  user: IUser;
  account: IAccount;
  publicAnnouncement: PublicAnnouncementType;
  images: IPaymentLink[];
  subdomainLink: string;
  isV2?: boolean;
};

const TopProfile: FC<TopProfileType> = ({
  account,
  images,
  publicAnnouncement,
  username,
  user,
  subdomainLink,
  isV2,
}) => {
  const [bannerCount, setBannerCount] = useState(0);
  const banner = publicAnnouncement?.banner
    ? publicAnnouncement?.banner?.value
    : false;

  if (banner && images?.length > 0 && images?.length - bannerCount > 0) {
    return (
      <BannerCatalog
        setBannerCount={setBannerCount}
        bannerCount={bannerCount}
        user={user}
        subdomainLink={subdomainLink}
        username={username}
        images={images}
      />
    );
  }

  if (isV2) {
    return (
      <div className="flex flex-col items-center gap-2.5 mt-10">
        <Image
          alt="Merchant Logo"
          src={account?.logo?.url || DEFAULT_LOGO_URL!}
          className="h-[96px] rounded-full mb-4"
          width="96"
          height="96"
        />
        <h1 className="flex items-center font-[500]">
          {account.name}
          {account.isVerified && (
            <Image
              src={"/check.png"}
              alt="Verified account"
              className={`ml-[5px] ${!!account?.profile ? "mb-0" : "mb-5"}`}
              width="17"
              height="17"
            />
          )}
        </h1>
        {!!account?.profile ? <p className="mb-5">{account.profile}</p> : null}
      </div>
    );
  }

  return (
    <>
      {!banner || images?.length - bannerCount === 0 ? (
        <div className="max-w-[860px] lg:w-auto w-[90%] mx-auto flex flex-col justify-center items-center mt-10">
          <img
            alt="logo"
            src={
              user?.account?.logo?.url ||
              process.env.NEXT_PUBLIC_DEFAULT_USER_AVATAR
            }
            width="80"
            className="rounded-circle mb-5 rounded-full"
          />

          <h2 className="mt-[10px] text-[1.2rem]">
            {user?.account?.name}
            {user?.account?.isVerified && (
              <img
                src={"/check.png"}
                alt="Verified account"
                className="mb-[5px] ml-[5px]"
                width="17"
              />
            )}
          </h2>
          <p className="mb-0 mt-2">{user?.account?.profile}</p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TopProfile;
